<template>
  <!-- <b-row class="justify-content-md-center pt-4">
    <b-col md="6"> -->
  <!-- <b-card text-variant="dark" class="h-auto"> -->
 <!-- <div>      <b-card-title class="font-weight-bolder">Activa tu cuenta</b-card-title>
        <div class="demo-inline-spacing pb-2">
          <feather-icon
            icon="MailIcon"
            size="64"
          />
          <feather-icon
            icon="ArrowRightIcon"
            size="64"
          />
          <feather-icon
            icon="CheckCircleIcon"
            size="64"
          />
        </div>
        <b-card-text class="text-dark">
          Estás a un paso de poder empezar, ve a tu correo electrónico  <strong>{{ email }}</strong> y da clic en el correo de confirmación
          para activar tu cuenta.
        </b-card-text>
        <b-alert show variant="light">Revisa tu bandeja de spam en caso que no veas el correo en tu Bandeja de
           Entrada.</b-alert>
           <b-button @click="resend()" variant="ligth-secondary">Reenviar email</b-button>
        <b-button @click="next()" variant="primary" size="lg">Perfecto</b-button>  -->
  <terms-and-condition></terms-and-condition>

  <!-- </b-card>  -->
  <!-- </b-col>
  </b-row> -->
</template>
<script>
//import {  } from "bootstrap-vue";
import service from "@/services/others";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TermsAndCondition from "./Terms-and-condition.vue";
// import { directive } from "vue-awesome-swiper";
export default {
  components: {
    TermsAndCondition,
    // BCardText,
    // BCardTitle,
    // BRow,
    // BCol,
  },
  data() {
    return {
      email: "",
    };
  },
  created() {
    this.email = this.getEmail();
    console.log("confirm view");
  },
  methods: {
    getEmail() {
      const user = JSON.parse(localStorage.userData);
      return user.email;
    },
    next() {
      this.$router.push("/dashboard");
    },
    resend() {
      service
        .sendEmailPass({ email: this.email }, true)
        .then((response) => {
          console.log(response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se ha enviado el link a tu correo.",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al enviar link al correo.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
